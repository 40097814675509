import React, { useState } from "react";
import Text from "../../../components/Text";
import { fontSizes } from "../../../constants/constants";
import dynamicfunction from "../../../utils/dynamicfunction";
import ViewReverse from "../../../components/ViewReverse";
import CustomSwitch from "../../../components/CustomSwitch";
import CustomDropdown from "../../../components/CustomDropdown";
import { dropdownOptions } from "../../../config/config";
import CustomInput from "../../../components/CustomInput";
import Button from "../../../components/Button";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { useSelector } from "react-redux";

const Redeem = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { fundsContent } = useSelector(state => state.content)

  const [redeemData, setRadeemData] = useState({
    isTotal: false,
    asset: "",
    fund: "",
    class: "",
    accounts: "",
    redeemedAmount: "",
  });

  const getContentKey = (key) => {
    return getContentKeyAccToLang(fundsContent?.web_funds_radeem?.data?.attributes, key, isArabic);
  }

  const onChangeData = (key, val) => {
    setRadeemData((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <div>
      <Text
        text={getContentKey("heading")}
        size={fontSizes.h24}
        weight={TextWeight.headingBold}
        color="text-dark-blue"
      />
      <Text
        text={getContentKey("complete_redeem")}
        size={fontSizes.h20}
        weight={TextWeight.regular}
        color="text-light-blue"
        className={"mb-3"}
      />

      <ViewReverse className={"gap-6"}>
        <Text
          text={getContentKey("redemption")}
          size={fontSizes.h14}
          color="text-primary-light"
        />
        <CustomSwitch
          leftText={getContentKey("partial")}
          rightText={getContentKey("total")}
          checked={redeemData.isTotal}
          onChange={() => onChangeData("isTotal", !redeemData.isTotal)}
        />
      </ViewReverse>

      <ViewReverse className={"gap-4 mt-4"}>
        <div className="w-full md:w-1/2">
          <CustomDropdown
            label={getContentKey("asset_class")}
            options={dropdownOptions}
            selectedValue={redeemData.asset}
            onSelect={(val) => onChangeData("asset", val)}
          />
        </div>
        <div className="w-1/2" />
      </ViewReverse>

      <ViewReverse className={"gap-4 mt-4"}>
        <div className="w-full md:w-1/2">
          <CustomDropdown
            label={getContentKey("fund")}
            options={dropdownOptions}
            selectedValue={redeemData.fund}
            onSelect={(val) => onChangeData("fund", val)}
          />
        </div>
        <div className="w-full md:w-1/2">
          <CustomDropdown
            label={getContentKey("class")}
            options={dropdownOptions}
            selectedValue={redeemData.class}
            onSelect={(val) => onChangeData("class", val)}
          />
        </div>
      </ViewReverse>

      <ViewReverse className={"gap-4 mt-4"}>
        <div className="w-full md:w-1/2">
          <CustomInput
            label={getContentKey("redemption_amount")}
            placeholder={getContentKey("redemption_amount_placeholder")}
            isFullborder
            isRounded
          />
        </div>
        <div className="w-full md:w-1/2">
          <Text
            text={getContentKey("available_amount")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <Text
            text={"3,292,035.9"}
            color="text-grak-grey"
            size={fontSizes.h14}
            weight={TextWeight.light}
            className={"mt-4"}
          />
        </div>
      </ViewReverse>

      <Text text={getContentKey("or")} size={fontSizes.h14} color="text-primary-light mt-3" />

      <ViewReverse className={"gap-4 mt-2"}>
        <div className="w-full md:w-1/2">
          <CustomInput
            label={getContentKey("units_redeem")}
            placeholder={"Enter quantity"}
            isFullborder
            isRounded
          />
        </div>
        <div className="w-full md:w-1/2">
          <Text
            text={getContentKey("available_unit")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <Text
            text={"3,292,035.9"}
            color="text-grak-grey"
            size={fontSizes.h14}
            weight={TextWeight.light}
            className={"mt-4"}
          />
        </div>
      </ViewReverse>

      <ViewReverse className={"gap-4 mt-4"}>
        <div className="w-full md:w-1/2">
          <CustomDropdown
            label={getContentKey("transfer_amount")}
            options={dropdownOptions}
            selectedValue={redeemData.redeemedAmount}
            onSelect={(val) => onChangeData("redeemedAmount", val)}
          />
        </div>
        <div className="w-full md:w-1/2">
          <CustomDropdown
            label={getContentKey("available_accounts")}
            options={dropdownOptions}
            selectedValue={redeemData.accounts}
            onSelect={(val) => onChangeData("accounts", val)}
          />
        </div>
      </ViewReverse>

      <ViewReverse className="items-center w-full justify-end gap-4 mt-8">
        <Button
          text={getContentKey("clear_btn")}
          bgColor="bg-white"
          color={"text-dark-grey"}
          className={
            "w-36 text-center border border-dark-grey border-opacity-50"
          }
        />
        <Button
          text={getContentKey("review_btn")}
          bgColor="bg-orange"
          className={"w-36 text-center border border-orange"}
          // onClick={toogleTermModal}
        />
      </ViewReverse>
    </div>
  );
};

export default Redeem;
