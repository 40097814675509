import React from "react";
import images from "../../constants/images";
import Header from "../../components/Header";
import Text from "../../components/Text";
import { fontSizes } from "../../constants/constants";
import ViewItems from "../../components/ViewItems";
import homeFunctional from "./index.functional";

const Home = () => {
  const { TextWeight, getContentKey } = homeFunctional();

  return (
    <div
      className={`h-screen w-full bg-no-repeat bg-top bg-cover py-6 px-4`}
      style={{ backgroundImage: `url(${images.heroBg})` }}
    >
      <div className="mx-auto px-4 sm:px-6 md:px-8 lg:px-10 h-full">
        <Header />

        <div className="h-40" />

        <ViewItems className={`w-full`}>
          <div className={`bg-white h-[2px] w-1/2`} />

          <div className={`mt-5`}>
            <Text
              text={getContentKey('heading')}
              color={"text-white"}
              weight={TextWeight.headingRegular}
              size={fontSizes.h80}
            />
          </div>
        </ViewItems>
      </div>
    </div>
  );
};

export default Home;
