import { useEffect, useState } from "react";
import { getBrokerageData, getClientData } from "../../store/actions/investmentsAction";
import { useDispatch } from "react-redux";


export default () => {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState(0);
    const [selectedChild, setSelectedChild] = useState('performance');

    const onPressMenu = (val) => setSelected(val);

    useEffect(() => {
        dispatch(getClientData());
        dispatch(getBrokerageData());
    }, [])

    return {
        selected,
        onPressMenu,
        selectedChild,
        setSelectedChild,
    }
}