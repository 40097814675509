import { useState } from "react"


export default () => {
    const [selected, setSelected] = useState(0);

    const onPressMenu = (val) => setSelected(val);


    return {
        selected,
        onPressMenu,
    }
}