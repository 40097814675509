import heroBg from '../assets/images/hero_bg.png';
import textLogo from '../assets/images/text_logo.png';
import loginBg from '../assets/images/login_bg.png';
import logo from '../assets/images/logo.png';
import mountains from '../assets/images/mountains.png';

const images = {
    heroBg,
    textLogo,
    loginBg,
    logo,
    mountains,
};

export default images;