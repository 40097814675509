import React, { useEffect, useRef, useState } from "react";
import ViewReverse from "./ViewReverse";
import images from "../constants/images";
import { getContentKeyAccToLang } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Text from "./Text";
import dynamicfunction from "../utils/dynamicfunction";
import { fontSizes } from "../constants/constants";
import Button from "./Button";
import icons from "../constants/icons";
import Language from "./Language";
import { tabsMenu } from "../config/config";
import { useSearchParams } from "react-router-dom";
import { onLogout } from "../store/slices/authSlice";
import { ReactSVG } from "react-svg";

const DashboardHeader = () => {
  const { isArabic, TextWeight } = dynamicfunction();
  const { dashboardContent } = useSelector((state) => state.content);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isDrawer, setIsDrawer] = useState(false);

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams((params) => {
        params.set("tab", "investments");
        return params;
      });
    }
  }, []);

  const getContentKey = (key) => {
    return getContentKeyAccToLang(dashboardContent, key, isArabic);
  };

  const handleTabPress = (tab) => {
    setSearchParams((params) => {
      params.set("tab", tab);
      return params;
    });
  };

  const handleLogout = () => {
    dispatch(onLogout());
    window.location.reload();
  };

  const toogleDrawer = () => setIsDrawer(!isDrawer);

  return (
    <div>
      <ViewReverse className={"p-4 justify-between bg-white"}>
        <ViewReverse className={"items-center gap-2"}>
          <img src={images.logo} className="w-10 h-10" />
          <Text
            text={getContentKey("logo_text")}
            color={"text-primary-dark"}
            weight={TextWeight.light}
            size={fontSizes.h18}
          />
        </ViewReverse>
        <div className="hidden sm:flex">
          <ViewReverse className={"items-center gap-4"}>
            <img src={icons.search} />
            <Language />
            <div className="relative">
              <img src={icons.bell} />
            </div>
            <div className="w-[1.5px] h-8 bg-grey" />
            <div className="flex gap-2">
              <Text
                text={"Hamza Al-ghannam"}
                color={"text-dark-grey"}
                className={"uppercase"}
                weight={TextWeight.bold}
              />
              <img src={icons.downArrow} />
            </div>
            <Button
              text={getContentKey("logout_btn")}
              bgColor="bg-transparent"
              color="text-black"
              className={"border border-grey w-28"}
              weight={TextWeight.regular}
              onClick={handleLogout}
            />
          </ViewReverse>
        </div>
        <div className="flex sm:hidden">
          <ReactSVG
            src={icons.menuList}
            className="cursor-pointer"
            onClick={toogleDrawer}
          />
        </div>
      </ViewReverse>

      <ViewReverse
        className={
          "px-4 pt-4 items-end h-44 bg-no-repeat bg-cover bg-left-top bg-red-500 overflow-x-scroll"
        }
        style={{
          backgroundImage: `url(${images.mountains})`,
        }}
      >
        {tabsMenu.map((item, index) => {
          const selected = searchParams.get("tab") == item.route;
          return (
            <div
              onClick={() => handleTabPress(item.route)}
              key={index}
              id={item?.route}
            >
              <ViewReverse
                className={`gap-3 cursor-pointer px-8 pt-3 pb-4 justify-center items-center rounded-tr rounded-tl ${
                  selected ? "bg-sky-blue" : "bg-transparend"
                } transition-all duration-300`}
              >
                <Text
                  text={getContentKey(item.key)}
                  size={fontSizes.h14}
                  color={selected ? "text-primary-light" : "text-white"}
                  className={"text-nowrap"}
                />
                <img
                  src={selected ? icons.downArrowYellow : icons.downArrowWhite}
                />
              </ViewReverse>
            </div>
          );
        })}
      </ViewReverse>

      <div className={`h-screen w-full flex justify-end fixed top-0 right-0 bg-black bg-opacity-50 ${
          isDrawer ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300 `}>
      <div
        className={`h-screen bg-white w-4/5 p-4`}
      >
        <ReactSVG src={icons.cross} className="cursor-pointer" onClick={toogleDrawer} />

        <img src={images.textLogo} className="h-10 mx-auto mt-2" />
      </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
