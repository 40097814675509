import axios from "axios";
import { setContent, setLanguage } from "../slices/contentSlice";

export const changeLanguage = (language) => {
  return async (dispatch) => {
    dispatch(setLanguage({language}));
  };
};

export const getContent = () => {
    return async (dispatch) => {
        try {
            const url = process.env.REACT_APP_CONTENT_URL
        } catch (error) {
        }
    }
}

export const getStrapiContent = () => {
  return async (dispatch) => {
  try {
    const response = await axios.get(process.env.REACT_APP_CONTENT_URL);
    const data = response?.data?.data?.[0]?.attributes;
    // console.log('strapi api called')
    
    const contentData = {
      authContent: data?.web_auth?.data?.attributes,
      dashboardContent: data?.web_dashboard?.data?.attributes,
      settingContent: data?.web_setting?.data?.attributes,
      investmentContent: data?.web_investment?.data?.attributes,
      fundsContent: data?.web_fund?.data?.attributes,
    };
    dispatch(setContent(contentData));
  } catch (error) {
    
  }}
}
