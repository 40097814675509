import downArrow from '../assets/icons/down_arrow.svg';
import search from '../assets/icons/search.svg';
import menuList from '../assets/icons/menu_list.svg';
import back from '../assets/icons/back.svg';
import bell from '../assets/icons/bell.svg';
import downArrowYellow from '../assets/icons/down_arrow_yellow.svg';
import downArrowWhite from '../assets/icons/down_arrow_white.svg';
import person from '../assets/icons/person.svg';
import state from '../assets/icons/state.svg';
import selectedBell from '../assets/icons/selected_bell.svg';
import selectedPerson from '../assets/icons/selected_person.svg';
import selectedState from '../assets/icons/selected_states.svg';
import dropdownArrow from '../assets/icons/dropdown_arrow.svg';
import openLink from '../assets/icons/open_link.svg';
import home from '../assets/icons/home.svg';
import homeSelected from '../assets/icons/home_selected.svg';
import pieChart from '../assets/icons/pie_chart.svg';
import pieChartSelected from '../assets/icons/pie_chart_selected.svg';
import bag from '../assets/icons/bag.svg';
import bagSelected from '../assets/icons/bag_selected.svg';
import upArrowSelected from '../assets/icons/up_arrow_selected.svg';
import plus from '../assets/icons/plus.svg';
import plusSelected from '../assets/icons/plus_selected.svg';
import download from '../assets/icons/download.svg';
import downloadSelected from '../assets/icons/download_selected.svg';
import roundArrow from '../assets/icons/round_arrow.svg';
import roundArrowSelected from '../assets/icons/round_arrow_selected.svg';
import slip from '../assets/icons/slip.svg';
import slipSelected from '../assets/icons/slip_selected.svg';
import cross from '../assets/icons/cross.svg';
import rightArow from '../assets/icons/right_arrow.svg';
import settings from '../assets/icons/settings.svg';
import dots from '../assets/icons/dots.svg';
import modalCross from '../assets/icons/modal_cross.svg';
import edit from '../assets/icons/edit.svg';

const icons = {
    downArrow,
    search,
    menuList,
    back,
    bell,
    downArrowWhite,
    downArrowYellow,
    person,
    state,
    selectedBell,
    selectedPerson,
    selectedState,
    dropdownArrow,
    openLink,
    home,
    homeSelected,
    pieChart,
    pieChartSelected,
    bag,
    bagSelected,
    upArrowSelected,
    plus,
    plusSelected,
    download,
    downloadSelected,
    roundArrow,
    roundArrowSelected,
    slip,
    slipSelected,
    cross,
    rightArow,
    settings,
    dots,
    modalCross,
    edit,
};

export default icons;