import { ReactSVG } from "react-svg";
import Text from "../components/Text";
import { fontSizes } from "../constants/constants";
import icons from "../constants/icons";

export const languages = {
  eng: "English",
  arb: "Arabic",
};

export const tabsMenu = [
  {
    id: 0,
    key: "investments",
    route: "investments",
  },
  {
    id: 1,
    key: "funds",
    route: "funds",
  },
  {
    id: 2,
    key: "trading",
    route: "trading",
  },
  {
    id: 3,
    key: "transfers",
    route: "transfers",
  },
  {
    id: 4,
    key: "reports",
    route: "reports",
  },
  {
    id: 5,
    key: "settings",
    route: "settings",
  },
  {
    id: 6,
    key: "help",
    route: "help",
  },
];
export const investmentsMenu = [
  {
    id: 0,
    label: "Overview",
    icon: icons.home,
    activeIcon: icons.homeSelected,
  },
  {
    id: 1,
    label: "Managed investments",
    icon: icons.pieChart,
    activeIcon: icons.pieChartSelected,
    childs: [
      {
        key: "performance",
        label: "Performance",
      },
      {
        key: "allocation",
        label: "Allocation",
      },
      {
        key: "holdings",
        label: "Holdings",
      },
    ],
  },
  {
    id: 2,
    label: "Brokerage",
    icon: icons.bag,
    activeIcon: icons.bagSelected,
  },
];

export const settingsMenu = [
  {
    id: 0,
    label: "General Settings",
    icon: icons.person,
    activeIcon: icons.selectedPerson,
  },
  {
    id: 1,
    label: "Notification Settings",
    icon: icons.bell,
    activeIcon: icons.selectedBell,
  },
  {
    id: 2,
    label: "Trading Settings",
    icon: icons.state,
    activeIcon: icons.selectedState,
  },
];

export const fundsMenu = [
  {
    id: 0,
    label: "Subscribe",
    icon: icons.plus,
    activeIcon: icons.plusSelected,
  },
  {
    id: 1,
    label: "Redeem",
    icon: icons.download,
    activeIcon: icons.downloadSelected,
  },
  {
    id: 2,
    label: "Switch",
    icon: icons.roundArrow,
    activeIcon: icons.roundArrowSelected,
  },
  {
    id: 3,
    label: "Transactions",
    icon: icons.slip,
    activeIcon: icons.slipSelected,
  },
];

export const languageDropdown = [
  {
    key: "select",
    label: "Select Language",
  },
  {
    key: 1,
    label: "English",
  },
  {
    key: 2,
    label: "Arabic",
  },
];

export const positionOptions = [
  {
    key: "bottom-right",
    label: "Bottom - right",
  },
  {
    key: "bottom-left",
    label: "Bottom - left",
  },
  {
    key: "top-right",
    label: "Top - right",
  },
  {
    key: "Top-left",
    label: "top - left",
  },
];

export const orderSideOptions = [
  {
    key: "buy",
    label: "Buy",
  },
  {
    key: "sell",
    label: "Sell",
  },
];

export const goodTillOptions = [
  {
    key: "day",
    label: "Day",
  },
  {
    key: "night",
    label: "Night",
  },
];

export const buyModeOptions = [
  {
    key: "limit",
    label: "Limit order",
  },
];

export const fillTermsOptions = [
  {
    key: "market-default",
    label: "Market default",
  },
];

export const sellModeOptions = [
  {
    key: "stop-market-sell",
    label: "Stop market sell",
  },
];

export const portfolioOptions = [
  {
    key: "regular-dfm",
    label: "1 - Regular DFM",
  },
];

export const cashAccountOptionds = [
  {
    key: "0671127728901",
    label: "0671127728901",
  },
];

export const dropdownOptions = [
  {
    key: 0,
    label: "Option 1",
  },
  {
    key: 1,
    label: "Option 2",
  },
  {
    key: 2,
    label: "Option 3",
  },
];

export const transactionsFilterOptions = (getContentKey) => [
  {
    key: 0,
    label: getContentKey("all"),
  },
  {
    key: 1,
    label: getContentKey("subscription"),
  },
  {
    key: 2,
    label: getContentKey("redemption"),
  },
  {
    key: 3,
    label: getContentKey("switch"),
  },
];

export const overViewTableData = [
  {
    id: 0,
    col1: "",
    col2: "Balance",
    col3: "% of net assets",
  },
  {
    id: 1,
    col1: "Managed investments",
    col2: "176,865,715.45",
    col3: 95,
  },
  {
    id: 2,
    col1: "Brokerage",
    col2: "34,400.00",
    col3: 5,
  },
  {
    id: 3,
    col1: "Total",
    col2: "186,681,514.45",
    col3: "100%",
  },
];

export const brokerageHoldingsColumns = (TextWeight, getContentKey) => [
  {
    title: () => (
      <Text
        text={getContentKey("current_investment")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "fundName",
    key: "fundName",
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("quantity")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "quantity",
    key: "quantity",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("total_cost")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "totalCost",
    key: "totalCost",
    render: (text) => (
      <Text
        text={text.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("avg_cost")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "averageCost",
    key: "averageCost",
    render: (text) => (
      <Text
        text={text.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("market_price")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "marketPrice",
    key: "marketPrice",
    render: (text) => (
      <Text
        text={text.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("market_value")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "marketValueSar",
    key: "marketValueSar",
    render: (text) => (
      <Text
        text={text.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("gain_loss")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "a",
    key: "a",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("validation_date")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "valuationDate",
    key: "valuationDate",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
];

export const cashBalanceColumns = (TextWeight, getContentKey) => [
  {
    title: () => (
      <Text
        text={getContentKey("investment_portfolio")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "fundName",
    key: "fundName",
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("currency")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "currency",
    key: "currency",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("value")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "value",
    key: "value",
    render: (text) => (
      <Text
        text={text.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("value_sar")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "valueSar",
    key: "valueSar",
    render: (text) => (
      <Text
        text={text.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("allocation_percentage")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "allocationPercentage",
    key: "allocationPercentage",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
];

export const transactionsColumns = (TextWeight, getContentKey) => [
  {
    title: () => (
      <ReactSVG src={icons.settings} />
    ),
    dataIndex: "id",
    key: "id",
    render: () => <ReactSVG src={icons.dots} className="ms-1" />,
    width: 10,
  },
  {
    title: () => (
      <Text text={getContentKey("table_id")} size={fontSizes.h14} weight={TextWeight.bold} />
    ),
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text text={getContentKey("type")} size={fontSizes.h14} weight={TextWeight.bold} />
    ),
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.length - b.type.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text text={getContentKey("fund")} size={fontSizes.h14} weight={TextWeight.bold} />
    ),
    dataIndex: "fund",
    key: "fund",
    sorter: (a, b) => a.fund.length - b.fund.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text text={getContentKey("amount")} size={fontSizes.h14} weight={TextWeight.bold} />
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: (a, b) => a.amount - b.amount,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text text={getContentKey("date")} size={fontSizes.h14} weight={TextWeight.bold} />
    ),
    dataIndex: "date",
    key: "date",
    sorter: (a, b) => {
      const dateA = new Date(a.date.split('/').reverse().join('/'));
      const dateB = new Date(b.date.split('/').reverse().join('/'));
      return dateA - dateB;
    },
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text text={getContentKey("status")} size={fontSizes.h14} weight={TextWeight.bold} />
    ),
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.length - b.status.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
];

export const transactionsData = [
  {
    key: 0,
    id: 64147714671541,
    type: "Subscription",
    fund: "Tadawul 1",
    amount: 2000,
    date: "18/06/2019",
    status: "Submitted",
  },
  {
    key: 1,
    id: 98729371837361,
    type: "Redemption",
    fund: "Tadawul 2",
    amount: 230,
    date: "23/05/2018",
    status: "Completed",
  },
  {
    key: 2,
    id: 51938931801018,
    type: "Switch",
    fund: "Tadawul 3",
    amount: 100,
    date: "28/04/2018",
    status: "Completed",
  },
];
