import React, { useState } from "react";
import Text from "../../../components/Text";
import { fontSizes } from "../../../constants/constants";
import dynamicfunction from "../../../utils/dynamicfunction";
import CustomDatePicker from "../../../components/CustomDatePicker";
import ViewReverse from "../../../components/ViewReverse";
import CustomDropdown from "../../../components/CustomDropdown";
import {
  transactionsColumns,
  transactionsData,
  transactionsFilterOptions,
} from "../../../config/config";
import { ConfigProvider, Divider, Modal, Table } from "antd";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import ViewItems from "../../../components/ViewItems";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { useSelector } from "react-redux";

const Transactions = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { fundsContent } = useSelector((state) => state.content);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      fundsContent?.web_funds_transaction?.data?.attributes,
      key,
      isArabic
    );
  };

  return (
    <div>
      <Text
        text={getContentKey("heading")}
        size={fontSizes.h24}
        weight={TextWeight.headingBold}
        color="text-dark-blue"
      />

      <ViewReverse className={"gap-4"}>
        <CustomDatePicker
          label={getContentKey("from")}
          onChange={(date, dateString) => setFrom(dateString)}
          width="w-1/3"
        />
        <CustomDatePicker label={getContentKey("to")}
        onChange={(date, dateString) => setTo(dateString)}
        width="w-1/3"
        />
      </ViewReverse>

      <div className="border border-grey rounded-md px-4 pb-4 mt-4">
        <ViewReverse className={"justify-between items-center"}>
          <Text
            text={getContentKey("transaction_list")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <div className="w-40">
          <CustomDropdown
            selectedValue={selectedFilter}
            options={transactionsFilterOptions(getContentKey)}
            onSelect={(val) => setSelectedFilter(val)}
            isBorder={false}
            justify="justify-end"
            top="top-10"
          />
          </div>
        </ViewReverse>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#E0E0E0",
                headerSplitColor: "transparent",
                borderRadius: 0,
                padding: 15,
              },
            },
          }}
        >
          <Table
            columns={isArabic ? transactionsColumns(TextWeight, getContentKey)?.reverse() : transactionsColumns(TextWeight, getContentKey)}
            dataSource={transactionsData}
            pagination={false}
            className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
            scroll={{
              x: "max-content",
            }}
            onRow={(i) => ({
              onClick: (e) => {
                setIsModalOpen(true)
              },
            })}
          />
        </ConfigProvider>
      </div>

      <Modal
      open={isModalOpen}
      closeIcon={false}
      footer={null}
      className="top-5"
      >

        <ViewReverse className={'justify-between'}>
            <ViewReverse className={'items-end gap-2'}>
                <Text
                text="Transactions"
                color="text-dark-blue"
                size={fontSizes.h24}
                weight={TextWeight.bold}
                />
                <Text
                text="8000866041 Transaction Details"
                color="text-dark-blue"
                size={fontSizes.h14}
                className={'mb-[5px]'}
                />
            </ViewReverse>
            <ReactSVG src={icons.modalCross} className="opacity-50 cursor-pointer" onClick={() => setIsModalOpen(false)} />
        </ViewReverse>

        <ViewReverse className={'mt-1 justify-between items-center border border-grey bg-grey bg-opacity-30 px-4 py-3 rounded'}>

            <ViewReverse className={'gap-4'}>
                <ViewItems className={'gap-2'}>
                    <Text
                    text="1020"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                    />
                    <Text
                    text="Al-Jazira Bank"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    />
                </ViewItems>
                <ViewItems className={'gap-2'}>
                    <Text
                    text="Queued"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                    />
                    <Text
                    text="Limit order"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    />
                </ViewItems>
            </ViewReverse>

            <ViewReverse className={'items-center gap-4'}>
                <ReactSVG src={icons.roundArrow} />
                <ReactSVG src={icons.modalCross} />
                <ReactSVG src={icons.edit} />
            </ViewReverse>

        </ViewReverse>

        <ViewItems className={'mt-4 border border-grey rounded px-4 py-3'}>
            <Text
            text="Cancelled or rejected transactions"
            color="text-primary-light"
            size={fontSizes.h14}
            />

            <ViewReverse className={'w-full mt-2 mb-3 gap-4'}>
                <div className="w-1/2">
                    <ViewReverse className={'justify-between'}>
                        <Text
                        text="Remaining quantity"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="12"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Average ex. Price"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0.0000"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                    
                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Executed fees"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0.00"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Last executed time"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                </div>

                <div className="w-1/2">
                    <ViewReverse className={'justify-between'}>
                        <Text
                        text="Executed quantity"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Executed amount"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0.00"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                    
                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Total executed amount"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0.00"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Market respond"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                </div>
            </ViewReverse>

        </ViewItems>

        <ViewItems className={'mt-4 border border-grey rounded px-4 py-3'}>
            <Text
            text="Cancelled/Rejected transactions"
            color="text-primary-light"
            size={fontSizes.h14}
            />

            <ViewReverse className={'w-full mt-2 mb-3 gap-4'}>
                <div className="w-1/2">
                    <ViewReverse className={'justify-between'}>
                        <Text
                        text="Portfolio"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="1-TADAWUL1"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Fill term"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="Market default"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                    
                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Order date"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="14/09/2020"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Disclosed volume"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Fees amount"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0.20"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                </div>

                <div className="w-1/2">
                    <ViewReverse className={'justify-between'}>
                        <Text
                        text="Cash account"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="78110000167"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Min quantity"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="0"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                    
                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Expiry date"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="14/09/2020"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-between mb-1'}>
                        <Text
                        text="Order amount"
                        size={fontSizes.h14}
                        />
                        <Text
                        text="198.00"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />

                    <ViewReverse className={'justify-end mb-1'}>
                        <Text
                        text="198.00"
                        color="text-dark-blue"
                        size={fontSizes.h14}
                        />
                    </ViewReverse>
                    <Divider className="mt-1 mb-2 bg-grey" />
                </div>
            </ViewReverse>
        </ViewItems>

      </Modal>
    </div>
  );
};

export default Transactions;
