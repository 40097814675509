import { useDispatch, useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../../utils/utils";
import dynamicfunction from "../../utils/dynamicfunction";
import { useSearchParams } from "react-router-dom";


export default () => {
    const { dashboardContent } = useSelector(state => state.content);
    const { isArabic, TextWeight } = dynamicfunction();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const currentTab = searchParams.get('tab');

    const getContentKey = (key) => {
        return getContentKeyAccToLang(dashboardContent, key, isArabic);
    };

    return {
        TextWeight,
        currentTab,
        getContentKey,
    }
}