import React from "react";
import Sidebar from "../../components/Sidebar";
import { settingsMenu } from "../../config/config";
import settingsFunctional from "./index.function";
import GeneralSettings from "./components/GeneralSettings";
import NotificationSettings from "./components/NotificationSettings";
import TradingSettings from "./components/TradingSettings";

const Settings = () => {
  const { selected, onPressMenu } = settingsFunctional();

  return (
    <Sidebar
      menuList={settingsMenu}
      selected={selected}
      onPressMenu={onPressMenu}
    >
      {selected == 0 ? (
        <GeneralSettings />
      ) : selected == 1 ? (
        <NotificationSettings />
      ) : selected == 2 ? (
        <TradingSettings />
      ) : null}
    </Sidebar>
  );
};

export default Settings;
