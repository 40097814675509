import React, { useState } from "react";
import ViewItems from "../../../components/ViewItems";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import ViewReverse from "../../../components/ViewReverse";
import CustomInput from "../../../components/CustomInput";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { positionOptions } from "../../../config/config";
import CustomDropdown from "../../../components/CustomDropdown";
import Divider from "../../../components/Divider";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../../../utils/utils";

const NotificationSettings = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { settingContent } = useSelector((state) => state.content);
  console.log(settingContent);

  const [selectedPosition, setSelectedPosition] = useState();
  const [notificationType, setNotificationType] = useState({
    order: false,
    news: false,
    broadcast: false,
    alert: false,
  });
  const [newNotification, setNewNotification] = useState();

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      settingContent?.web_notification_setting?.data?.attributes,
      key,
      isArabic
    );
  };

  const onClickCkeck = (key, val) => {
    setNotificationType((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <ViewItems>
      <Text
        text={getContentKey("heading")}
        weight={TextWeight.headingRegular}
        size={fontSizes.h24}
        color={"text-dark-blue"}
      />

      <Text
        text={getContentKey("type")}
        size={fontSizes.h20}
        color={"text-light-blue"}
        className={"mt-2"}
      />

      <ViewReverse className={"gap-10 mt-4 mb-10"}>
        <div className="flex flex-col gap-2">
          <CustomCheckbox
            isChecked={notificationType.order}
            label={getContentKey("order_notification")}
            onClick={() => onClickCkeck("order", !notificationType.order)}
          />
          <CustomCheckbox
            isChecked={notificationType.broadcast}
            label={getContentKey("broadcast_notification")}
            onClick={() =>
              onClickCkeck("broadcast", !notificationType.broadcast)
            }
          />
        </div>
        <div className="flex flex-col gap-2">
          <CustomCheckbox
            isChecked={notificationType.news}
            label={getContentKey("new_notification")}
            onClick={() => onClickCkeck("news", !notificationType.news)}
          />
          <CustomCheckbox
            isChecked={notificationType.alert}
            label={getContentKey("alert")}
            onClick={() => onClickCkeck("alert", !notificationType.alert)}
          />
        </div>
      </ViewReverse>

      <Text
        text={getContentKey("display")}
        size={fontSizes.h20}
        color={"text-light-blue"}
      />

      <CustomCheckbox
        isChecked={newNotification}
        label={getContentKey("notification_panel")}
        className={"mt-4"}
        onClick={() => setNewNotification(!newNotification)}
      />

      <ViewReverse className={"w-full gap-[10%] mt-3"}>
        <CustomInput
          label={getContentKey("duration_label")}
          placeholder={"0"}
          isRounded
          isFullborder
        />
        <CustomDropdown
          label={getContentKey("position_label")}
          options={positionOptions}
          selectedValue={selectedPosition}
          onSelect={(val) => setSelectedPosition(val)}
        />
      </ViewReverse>

      <Divider className={"my-6"} />

      <Text
        text={getContentKey("recieve_notification_note")}
        color={"text-grey"}
        size={fontSizes.h14}
        weight={TextWeight.light}
      />

      <ViewReverse className="items-center w-full justify-end gap-2 mt-8 mb-6">
        <Button
          text={getContentKey('reset_btn')}
          color={"text-dark-grey"}
          bgColor="bg-white"
          className={
            "border border-dark-grey text-center w-32 px-0 border-opacity-50"
          }
        />
        <Button
          text={getContentKey('save_btn')}
          bgColor="bg-orange"
          className={"text-center w-32 px-0 border border-orange"}
        />
        <Button
          text={getContentKey('cancel_btn')}
          color={"text-dark-grey"}
          bgColor="bg-white"
          className={
            "border border-dark-grey text-center w-32 px-0 border-opacity-50"
          }
        />
      </ViewReverse>
    </ViewItems>
  );
};

export default NotificationSettings;
