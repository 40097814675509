import { get, post, put } from "./http-provider";

const SERVICE_URLS = {
  // Auth Management
  login: "auth/login",
  verifyOtp: "auth/verify-otp",
  resendCode: "auth/resend-otp",

  //Setting Management
  getLanguages: "general-setting/languages",
  changeLanguage: "general-setting/change-language/",
  changePassword: "general-setting/password-change",

  // Dashboard Management
  getClientDetails: "dashboard/client-details",
  getAllocation: "dashboard/allocation",
  getAllocationGraph: "dashboard/allocation-graph",
  getAllocationGraphGeography: "dashboard/allocation-graph-by-geography",
  getHoldings: "dashboard/holdings",
  getBrokerage: "dashboard/brokerage",
  getCurrencyDetails: "dashboard/currency-details",
  getBeneficiaryDetails: "dashboard/beneficiary-details",
  getBrokerage: "dashboard/brokerage",
};

// Auth Management
const login = (data) => post(SERVICE_URLS.login, data);
const verifyOtp = (data) => post(SERVICE_URLS.verifyOtp, data);
const resendCode = (data) => post(SERVICE_URLS.resendCode, data);
const getBrokerage = () => get(SERVICE_URLS.getBrokerage);

// Settign Management
const getLanguages = () => get(SERVICE_URLS.getLanguages);
const changeLanguage = (params) => put(SERVICE_URLS.changeLanguage + params);
const changePassword = (data) => post(SERVICE_URLS.changePassword, data);

// Dashboard Management
const getClientDetails = () => get(SERVICE_URLS.getClientDetails);

const apiServices = {
  login,
  verifyOtp,
  resendCode,
  getClientDetails,
  getBrokerage,
  getLanguages,
  changeLanguage,
  changePassword,
};

export default apiServices;