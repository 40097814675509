import React from "react";
import dynamicfunction from "../utils/dynamicfunction";
import { fontSizes } from "../constants/constants";

const Button = ({
  text,
  bgColor = "bg-orange",
  weight,
  color = "text-white",
  className,
  onClick,
  textSize,
  isDisabled = false,
}) => {
  const { TextWeight, TextAlign } = dynamicfunction();

  return (
    <button
      style={{
        fontSize: textSize ? textSize : fontSizes.h12,
      }}
      className={`py-2 px-0 ${ isDisabled ? 'bg-grey' : bgColor} ${
        weight ? weight : TextWeight.regular
      } ${isDisabled ? 'text-white' : color} rounded uppercase ${className}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default Button;
